
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.el-submenu .el-menu-item {
  min-width: auto !important;
  background-color: #1f2d3d !important;
}
.nav-menu {
  height: 100%;
  background-color: rgb(48, 65, 86);
}
.el-menu {
  height: 100%;
  border-right: none !important;
}
.el-menu-second .el-submenu__title {
  background-color: #1f2d3d !important;
}
.menu-icon{
  margin-bottom: -1px;
}
